export default function Nav() {
    return <nav className="nav" id="nav">
        <ul>
            <li>
                <a href="https://nangu.eco">Home</a>
            </li>
            <li>
                <a href="https://blog.nangu.eco">Blog</a>
            </li>
            <li>
                <a href="https://hub.nangu.eco">Hub</a>
            </li>
            <li>
                <a href="https://discord.gg/BdjTuJhNWm" target="_blank" rel="noreferrer">
                    Discord
                </a>
            </li>
            <li>
                <a href="https://static.nangu.eco/whitepaper/Nangu-Whitepaper-0.1.pdf" target="_blank" rel="noreferrer">
                    Whitepaper
                </a>
            </li>
        </ul>
    </nav>
}