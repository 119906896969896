export function Team() {
    return <div className="abox nftrees">
        <div className="aboxBackground"></div>
        <div className="aboxIntroContent">
            <div className="boxContainerHeader">
                <h3>
                    Team
                </h3>
            </div>
            <div>
                <h5 className="h5">Francisco Grau</h5>
                <img src="/team-francisco.png" />
                <p>Food Systems</p>
                <ul>
                    <li>Founder of feriaverde.org</li>
                    <li>NELIS & Nexus member</li>
                </ul>
            </div>
            <div>
                <h5 className="h5">Nikolai Onken</h5>
                <img src="/team-nikolai.png" />
                <p>Operations & Tech</p>
                <ul>
                    <li>Asymmetric</li>
                    <li>Amazon, AWS</li>
                </ul>
            </div>
            <div>
                <h5 className="h5">Ruben Daniels</h5>
                <img src="/team-ruben.png" />
                <p>Governance</p>
                <ul>
                    <li>Founder/CEO of Cloud9 (acquired by Amazon)</li>
                    <li>Social governance  and economics expert</li>
                </ul>
            </div>
            <div>
                <h5 className="h5">Angela Wante</h5>
                <img src="/team-angela.png" />
                <p>Finance</p>
                <ul>
                    <li>PWC</li>
                </ul>
            </div>
        </div>
    </div>
}