export function ReinventingFarming() {
  return <div className="abox revolution">
    <div className="aboxBackground"></div>
    <div className="aboxIntroContent">
      <div className="boxContainerHeader">
        <h3>
          Reinventing Farming
        </h3>
      </div>
      <div>
        <p>Nangu is developing a blueprint for global regeneration. Converting degraded farmland into regenerative forests that provide food, medicine, and materials. We invite rural families to learn, work, and partner with us.</p>
        <p>Nangu is an <strong>open source</strong>, replicable model for tropical regeneration. Our model combines regenerative farming, processing capabilities, entrepreneurial support, and world-class product development.</p>
      </div>
      <div className="boxImage">
        <img src="/nangu-farm.jpg" />
      </div>
    </div>
  </div>
}