import styled from 'styled-components'
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui'

import { PublicKey, Transaction } from '@solana/web3.js';
import { MintCountdown } from './MintCountdown';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import { sendTransaction } from './connection';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from './candy-machine';

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: white;
  background-color: #ff6f01 !important;
  font-size: 22px !important;
  font-weight: bold !important;
`;

const MintContainer = styled.div``; // add your owns styles here

// @ts-ignore
export function Mint({wallet, candyMachine, setAlertState, isPresale, isActive, isWhitelistUser, onMint, setIsUserMinting, isUserMinting, rpcUrl, connection}) {
  return <div className="abox blueprint">
    <div className="aboxBackground"></div>
    <div className="aboxIntroContent">
      <div className="boxContainerHeader">
        <h3>
          Join Nangu Now
        </h3>
        <p>Now is the time to invest in the pathways for future regeneration.
          We dream of thousands of Nangu Villages around the world in the next 20 years.
          We dream of all beings thriving. Do you?
        </p>
        <p>Please get in touch before minting the Nangu NFTree. You need a special token for minting.</p>
      </div>
      <div className="boxContainerHeader">
        {!wallet.connected ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
            <>
              {/*{candyMachine && (*/}
              {/*    <Grid*/}
              {/*        container*/}
              {/*        direction="row"*/}
              {/*        justifyContent="center"*/}
              {/*        wrap="nowrap"*/}
              {/*    >*/}
              {/*      <Grid item xs={3}>*/}
              {/*        <Typography variant="body2" color="textSecondary">*/}
              {/*          Remaining*/}
              {/*        </Typography>*/}
              {/*        <Typography*/}
              {/*            variant="h6"*/}
              {/*            color="textPrimary"*/}
              {/*            style={{*/}
              {/*              fontWeight: 'bold',*/}
              {/*            }}*/}
              {/*        >*/}
              {/*          {`${itemsRemaining}`}*/}
              {/*        </Typography>*/}
              {/*      </Grid>*/}
              {/*      <Grid item xs={4}>*/}
              {/*        <Typography variant="body2" color="textSecondary">*/}
              {/*          {isWhitelistUser && discountPrice*/}
              {/*              ? 'Discount Price'*/}
              {/*              : 'Price'}*/}
              {/*        </Typography>*/}
              {/*        <Typography*/}
              {/*            variant="h6"*/}
              {/*            color="textPrimary"*/}
              {/*            style={{ fontWeight: 'bold' }}*/}
              {/*        >*/}
              {/*          {isWhitelistUser && discountPrice*/}
              {/*              ? `◎ ${formatNumber.asNumber(discountPrice)}`*/}
              {/*              : `◎ ${formatNumber.asNumber(*/}
              {/*                  candyMachine.state.price,*/}
              {/*              )}`}*/}
              {/*        </Typography>*/}
              {/*      </Grid>*/}
              {/*      <Grid item xs={5}>*/}
              {/*        {isActive && endDate && Date.now() < endDate.getTime() ? (*/}
              {/*            <>*/}
              {/*              <MintCountdown*/}
              {/*                  key="endSettings"*/}
              {/*                  date={getCountdownDate(candyMachine)}*/}
              {/*                  style={{ justifyContent: 'flex-end' }}*/}
              {/*                  status="COMPLETED"*/}
              {/*                  onComplete={toggleMintButton}*/}
              {/*              />*/}
              {/*              <Typography*/}
              {/*                  variant="caption"*/}
              {/*                  align="center"*/}
              {/*                  display="block"*/}
              {/*                  style={{ fontWeight: 'bold' }}*/}
              {/*              >*/}
              {/*                TO END OF MINT*/}
              {/*              </Typography>*/}
              {/*            </>*/}
              {/*        ) : (*/}
              {/*            <>*/}
              {/*              <MintCountdown*/}
              {/*                  key="goLive"*/}
              {/*                  date={getCountdownDate(candyMachine)}*/}
              {/*                  style={{ justifyContent: 'flex-end' }}*/}
              {/*                  status={*/}
              {/*                    candyMachine?.state?.isSoldOut ||*/}
              {/*                    (endDate && Date.now() > endDate.getTime())*/}
              {/*                        ? 'COMPLETED'*/}
              {/*                        : isPresale*/}
              {/*                            ? 'PRESALE'*/}
              {/*                            : 'LIVE'*/}
              {/*                  }*/}
              {/*                  onComplete={toggleMintButton}*/}
              {/*              />*/}
              {/*              {isPresale &&*/}
              {/*                  candyMachine.state.goLiveDate &&*/}
              {/*                  candyMachine.state.goLiveDate.toNumber() >*/}
              {/*                  new Date().getTime() / 1000 && (*/}
              {/*                      <Typography*/}
              {/*                          variant="caption"*/}
              {/*                          align="center"*/}
              {/*                          display="block"*/}
              {/*                          style={{ fontWeight: 'bold' }}*/}
              {/*                      >*/}
              {/*                        UNTIL PUBLIC MINT*/}
              {/*                      </Typography>*/}
              {/*                  )}*/}
              {/*            </>*/}
              {/*        )}*/}
              {/*      </Grid>*/}
              {/*    </Grid>*/}
              {/*)}*/}
              <MintContainer>
                {candyMachine?.state.isActive &&
                candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                    <GatewayProvider
                        wallet={{
                          publicKey:
                              wallet.publicKey ||
                              new PublicKey(CANDY_MACHINE_PROGRAM),
                          //@ts-ignore
                          signTransaction: wallet.signTransaction,
                        }}
                        gatekeeperNetwork={
                          candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                        }
                        clusterUrl={rpcUrl}
                        handleTransaction={async (transaction: Transaction) => {
                          setIsUserMinting(true);
                          const userMustSign = transaction.signatures.find(sig =>
                              sig.publicKey.equals(wallet.publicKey!),
                          );
                          if (userMustSign) {
                            setAlertState({
                              open: true,
                              message: 'Please sign one-time Civic Pass issuance',
                              severity: 'info',
                            });
                            try {
                              transaction = await wallet.signTransaction!(
                                  transaction,
                              );
                            } catch (e) {
                              setAlertState({
                                open: true,
                                message: 'User cancelled signing',
                                severity: 'error',
                              });
                              // setTimeout(() => window.location.reload(), 2000);
                              setIsUserMinting(false);
                              throw e;
                            }
                          } else {
                            setAlertState({
                              open: true,
                              message: 'Refreshing Civic Pass',
                              severity: 'info',
                            });
                          }
                          try {
                            await sendTransaction(
                                connection,
                                wallet,
                                transaction,
                                [],
                                true,
                                'confirmed',
                            );
                            setAlertState({
                              open: true,
                              message: 'Please sign minting',
                              severity: 'info',
                            });
                          } catch (e) {
                            setAlertState({
                              open: true,
                              message:
                                  'Solana dropped the transaction, please try again',
                              severity: 'warning',
                            });
                            console.error(e);
                            // setTimeout(() => window.location.reload(), 2000);
                            setIsUserMinting(false);
                            throw e;
                          }
                          await onMint();
                        }}
                        broadcastTransaction={false}
                        options={{ autoShowModal: false }}
                    >
                      <MintButton
                          candyMachine={candyMachine}
                          isMinting={isUserMinting}
                          setIsMinting={val => setIsUserMinting(val)}
                          onMint={onMint}
                          isActive={isActive || (isPresale && isWhitelistUser)}
                      />
                    </GatewayProvider>
                ) : (
                    <MintButton
                        candyMachine={candyMachine}
                        isMinting={isUserMinting}
                        setIsMinting={val => setIsUserMinting(val)}
                        onMint={onMint}
                        isActive={isActive || (isPresale && isWhitelistUser)}
                    />
                )}
              </MintContainer>
            </>
        )}
      </div>
    </div>
  </div>
}