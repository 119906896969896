export function Legacy() {
  return <div className="abox roadmap">
    <div className="aboxBackground"></div>
    <div className="aboxIntroContent">
      <div className="boxContainerHeader">
        <h3>
          What do you want your legacy to be?
        </h3>
        <p>Investing in Nangu today will enable 9 rural families to grow food forever and find pathways to a regenerative future; while you take part in a profitable agricultural enterprise. </p>
      </div>
      <div className="boxContainerHeader contrastHeader">
      <div>
        <h4 className="h4">Raising:</h4>
        <h4 className="h4">USD $800.000</h4>
      </div>
      <div>
        <h4 className="h4">Investment returns:</h4>
        <p>30% of profits capped at 2x returns.</p>
      </div>
      </div>
      <div>
        <h5 className="h5">Distribution of funds:</h5>
        <p>A 3 year regenerative agriculture training for 9 families. It includes living wages, education, staff, tools, machinery and essential infrastructure for processing and delivery.</p>
      </div>
      <div>
        <h5 className="h5">Market opportunity:</h5>
        <p>Starting with known Eco-ethical niche markets in Costa Rica with a second phase of exports to soft commited buyers of medicinal herbs.</p>
      </div>
      <div className="boxImage noBorder verticalCenter">
        <img src="/nangu-fund-distribution.png" />
      </div>
      <div className="boxImage noBorder">
        <img src="/nangu-output.png" />
      </div>
      <div>
        With your investment, you also become a member of the Nangu Soul Coop
      </div>
    </div>
  </div>
}