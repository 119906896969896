export function FoodForever() {
  return <div className="abox blueprint">
    <div className="aboxBackground"></div>
    <div className="aboxIntroContent">
      <div className="boxContainerHeader">
        <h3>
          Growing Food Forever
        </h3>
      </div>
      <div>
        Our food systems are collapsing due to soil degradation, unsustainable use of water, farmers in harsh conditions, and climate change.
        <p className="noBorder boxImage horizontalCenter textCenter">
          <img src="/nangu-degraded-contrast.png" className="noBorder" />
        </p>
      </div>
      <div className="">
        There is an urgent need to create systems that provide food forever, revert soil degradation, dignify farming and recuperate lost arable land.
        <p className=" noBorder boxImage textCenter">
          <img src="/nangu-regenerated-contrast.png" />
        </p>
      </div>
    </div>
  </div>
}