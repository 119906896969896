export default function Landscape() {
    return <div className="landscape">
        <div className="landscapeBack landscapeLayer" data-modifier="10"></div>
        <div className="landscapeCenter landscapeLayer" data-modifier="30">
            <img src="/plant-bg-two.svg" width="350" className="imgPlant" alt="" />
            <img src="/tapir.svg" width="150" className="imgTapir" alt="" />
        </div>
        <div className="landscapeFront landscapeLayer" data-modifier="90">

            <img src="/plant-bg-three.svg" width="150" className="imgPlantTwo" alt="" />
            <img src="/plant-bg-one.svg" width="350" className="imgPlant" alt="" />
        </div>
    </div>
}